import tippy from 'tippy.js';

// SEARCH
var searchBtn = document.querySelectorAll('.app-minitools-search-btn');
var searchInput = document.querySelectorAll('.app-minitools-search-input');

if(searchBtn != null) {
  searchBtn.forEach(btn => {
    
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      searchOclc(e.currentTarget as HTMLElement);
    });
  })
}

if(searchInput != null) {
  searchInput.forEach(input => {
    input.addEventListener('keyup', (e: KeyboardEvent) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        searchOclc(e.currentTarget as HTMLElement);
      }
    });
  })
}

function searchOclc(searchElement: HTMLElement) {
  const parent = searchElement.closest('.app-minitools-search') as HTMLElement;
  const searchPage = parent.dataset.searchpage;
  const searchString = (parent.querySelector('.app-minitools-search-input') as HTMLInputElement).value;
  const url = `${searchPage}?search=${searchString}&top=y&module=1`;

  window.location.href = url;
}

// TOOLTIPS
var tooltipItems = document.querySelectorAll('.app-minitools-tooltips');
tooltipItems.forEach((item: HTMLElement) => {
  tippy(document.querySelector(`.nav-${item.dataset.id} a`), {
    content: item.dataset.content,
    placement: 'bottom',
  });
})

